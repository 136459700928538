.maps {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 20px;
}

.maps h1 {
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px;
}

.maps a {
  width: 100%;
  height: 100%;
}

.maps .render {
  margin: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  width: 90%;
  background-image: url("../../assets/images/searchMap.jpg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.maps .render:hover {
  opacity: 0.7;
}
