@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "OpenSauceOne-Regular";
  src: local("OpenSauceOne-Regular"),
    url("./assets/fonts/OpenSauceOne-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  background-color: #faf8f5;
  font-family: "OpenSauceOne-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Default styles for headings */
#contentitis {
  word-break: break-all;
}

#contentitis h1,
#contentitis h2,
#contentitis h3,
#contentitis h4,
#contentitis h5,
#contentitis h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; /* Adjust as needed */
}

#contentitis h1 {
  font-size: 2rem; /* Adjust as needed */
  font-weight: bold;
}

#contentitis h2 {
  font-size: 1.5rem; /* Adjust as needed */
  font-weight: bold;
}

#contentitis h3 {
  font-size: 1.17rem; /* Adjust as needed */
  font-weight: bold;
}

#contentitis h4 {
  font-size: 1rem; /* Adjust as needed */
  font-weight: bold;
}

#contentitis h5 {
  font-size: 0.83rem; /* Adjust as needed */
  font-weight: bold;
}

#contentitis h6 {
  font-size: 0.67rem; /* Adjust as needed */
  font-weight: bold;
}

/* Default styles for paragraphs */
#contentitis p {
  margin-top: 0;
  margin-bottom: 1rem; /* Adjust as needed */
}
