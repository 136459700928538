.founderContentWrapper {
  background-color: #cedfc3;
}

.founderContentWrapper h1 {
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px;
}

.founder {
  align-items: center;
  justify-content: center;
}

.founder p {
  margin: 10px;
}

.founder .content {
  padding: 12px;
  min-height: 350px;
}

.founder .content .innerContent {
  width: 75%;
  height: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
}

@media only screen and (max-width: 640px) {
  .founder .content .innerContent {
    flex-direction: column;
  }
}

.founder .content .innerContent .written {
  flex-direction: column;
  display: flex;
  align-items: start;
  justify-content: center;
  margin: 0 auto;
}

.founder .content .innerContent .header {
  display: flex;
  align-items: center;
  gap: 10px;
  text-align: start !important;
  width: 200px !important;
  justify-content: flex-start;
}

.founder .header img {
  height: 200px !important;
  border-radius: 100%;
  object-fit: cover;
  width: 200px !important;
  margin: 0 auto;
}
