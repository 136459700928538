.linking {
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
}

.linking .header {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.linking .header h1 {
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px;
}

.linking .header a {
  color: #304d50;
  font-size: 13px;
  cursor: pointer;
  margin-bottom: 20px;
}

.linking .header a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .linking .header {
    flex-direction: column;
  }
}

.linking .react-multi-carousel-list {
  height: 440px;
  width: 100%;
}

.linking .header .customButtons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.linking .header .customButtons .commonClass {
  width: 60px;
  height: 60px;
  max-height: 60px;
  max-width: 60px;
  min-height: 60px;
  min-width: 60px;
  border: 1px solid #e1e6f1;
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  transform-style: preserve-3d;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  text-decoration: none;
  transition: border-color 0.3s, transform 0.3s, background-color 0.3s,
    color 0.3s;
  display: flex;
}

.linking .header .customButtons .leftIcon {
  background-color: white;
  color: #111828;
}

.linking .header .customButtons .rightIcon {
  background-color: #111828;
  color: white;
}

.linking .header .customButtons .rightIcon:hover {
  /* background-color: #fc5761; */
  background-color: #304d50;
  color: white;
  transform: scale3d(1.06, 1.06, 1.01);
}

.linking .header .customButtons .leftIcon:hover {
  border-color: #111828;
  background-color: #111828;
  color: white;
  transform: scale3d(1.06, 1.06, 1.01);
}

.eventSlider .react-multiple-carousel__arrow {
  display: none !important;
}

.linking .card {
  width: 300px;
  position: relative;
  height: 320px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  color: rgba(47, 43, 61, 0.78);
  border-radius: 6px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  background-image: none;
  overflow: hidden;
}

.linking .card .bgImage {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 60%;
}

.linking .card .content {
  padding: 0.75rem 1.3125rem 1rem !important;
}

.linking .card .content h1 {
  margin: 0px 0px 0.5rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.3334;
  font-size: 1.125rem;
  color: rgba(47, 43, 61, 0.78);
}

.linking .card .content p {
  margin: 0px;
  line-height: 1.467;
  font-size: 0.9375rem;
  font-weight: 400;
  color: rgba(47, 43, 61, 0.68);
}

.linking .card button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.2;
  font-size: 0.9375rem;
  letter-spacing: 0.43px;
  font-weight: 500;
  border-radius: 0px 0px 6px 6px;
  color: rgb(255, 255, 255);
  background-color: #304d50;
  min-width: 50px;
  text-transform: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
  padding: 0.625rem 1.25rem;
  width: 100%;
}
