.slide {
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.slide img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin-top: 10px;
}

.slide button {
  margin: 4px;
}
