.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  margin: 0 auto;
  width: 90%;
  padding: 6px 20px;
}

.navbar .logoParent p {
  margin: 0px !important;
  font-size: 12px;
}

.navbar h1 {
  cursor: pointer;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  list-style: none;
}

.listInsideDrawer {
  display: flex !important;
  margin-top: 100px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
  padding: 30px auto;
  color: #222222;
}

.listInsideDrawer li {
  align-items: flex-start !important;
  justify-content: flex-start !important;
}

.list li {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-transform: uppercase;
}

.list li a {
  text-decoration: none;
  color: #222222;
}

@media only screen and (max-width: 640px) {
  .list {
    display: none;
  }
}
