.contact {
  display: flex;
  min-height: 75vh;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 30px 15px;
  /* background-color: #ead6e8; */
}

.contact h1 {
  color: rgb(3, 54, 61);
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px !important;
}

.contact .boxWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 12px;
  flex-wrap: wrap;
}

.contact .boxWrapper .getInTouchBox {
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  padding-right: 8px;
  min-height: 200px;
  margin: 20px;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  position: relative;
  transition: box-shadow 0.25s ease 0s;
  padding: 24px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: space-between;
  text-align: center;
  background: rgb(255, 255, 255);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
}

.contact .boxWrapper .getInTouchBox h2 {
  color: rgb(3, 54, 61);
  font-style: normal;
  line-height: calc(1em + 8px);
  margin: 0px 0px 24px;
  font-size: 18px !important;
  font-weight: 700;
}

.contact .boxWrapper .getInTouchBox a {
  text-overflow: clip;
  overflow: unset;
  -webkit-font-smoothing: antialiased;
  -webkit-box-align: center;
  text-transform: capitalize;
  align-items: center;
  display: inline-flex;
  font-family: "Sharp Sans", Arial, sans-serif;
  font-weight: 600;
  gap: 4px;
  -webkit-box-pack: center;
  justify-content: center;
  text-decoration: none;
  font-size: 16px;
  line-height: 28px;
  border-radius: 0px;
  cursor: pointer;
  min-width: 128px;
  padding: 8px 24px;
  text-align: center;
  width: 80%;
  background-color: rgb(23, 73, 77);
  border: 2px solid rgb(23, 73, 77);
  color: rgb(255, 255, 255);
}

@media only screen and (min-width: 1200px) {
  .contact h1 {
    font-size: 72px;
  }
}

@media only screen and (min-width: 768px) {
  .contact h1 {
    font-size: 56px;
  }

  .contact .boxWrapper .getInTouchBox h2 {
    font-size: 24px;
  }

  .contact .boxWrapper .getInTouchBox {
    max-width: 41.6667%;
  }
}
