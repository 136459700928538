.footer {
  display: flex;
  flex-wrap: wrap;
  background-color: #304d50;
  align-items: center;
  justify-content: space-between;
  padding: 12px 25px;
  color: white;
}

.footer h1 {
  font-size: 20px;
  margin: 0 !important;
}

.footer a {
  color: white;
  text-decoration: underline;
}
