.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formBox {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  gap: 1rem;
  width: 90%;
}

.textField {
  width: 100%;
  background-color: white;
}
