.event {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.event .noDataImage {
  margin: auto;
  background-color: white;
  width: 100%;
}

.scrollWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
}

.event .eventInputField {
  height: 35px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 6px;
  width: 65%;
}

.event .eventDataRender {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  margin: 25px auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.event .eventDataRender .content p {
  text-transform: none !important;
}

.event .fetchMoreButton {
  margin: 25px auto !important;
  width: max-content;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #304d50;
  cursor: pointer;
  color: white;
}

/* @media screen and (min-width: 1400px) {
  .event .eventDataRender {
    grid-template-columns: repeat(4, 1fr);
  }
} */

@media screen and (max-width: 991px) {
  .event .eventDataRender {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .event .eventDataRender {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }
}

.event .content {
  width: 75%;
  margin: 0 auto 25px;
}

.event .content img {
  max-width: 100%;
  max-height: 400px;
  border-radius: 12px;
}

.event .content .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event .content .header .share {
  display: flex;
  justify-content: center;
  gap: 15px;
  align-items: center;
}

.event .content .header .share a {
  color: #304d50;
}

.event .content .header .share a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 768px) {
  .event .content {
    width: 90%;
  }
}

.card {
  width: 300px;
  position: relative;
  height: 320px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  color: rgba(47, 43, 61, 0.78);
  border-radius: 6px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  background-image: none;
  overflow: hidden;
}

.card .bgImage {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 60%;
}

.card .content {
  padding: 0.75rem 1.3125rem 1rem !important;
}

.card .content h1 {
  margin: 0px 0px 0.5rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.3334;
  /* text-transform: capitalize; */
  font-size: 1.125rem;
  color: rgba(47, 43, 61, 0.78);
}

.card .content p {
  margin: 0px;
  line-height: 1.467;
  font-size: 0.9375rem;
  font-weight: 400;
  text-transform: none !important;
  color: rgba(47, 43, 61, 0.68);
}

.card button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.2;
  font-size: 0.9375rem;
  letter-spacing: 0.43px;
  font-weight: 500;
  border-radius: 0px 0px 6px 6px;
  color: rgb(255, 255, 255);
  background-color: #304d50;
  min-width: 50px;
  text-transform: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
  padding: 0.625rem 1.25rem;
  width: 100%;
}
