.directoryContentParent .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.directoryContentParent .moreCategories {
  width: 70%;
  margin: 50px auto;
}

.directoryContentParent .moreCategories .listBox {
  width: 250px;
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  padding: 12px;
  margin: 0 10px;
  border-radius: 12px;
  background-color: white;
  height: 300px;
}
.directoryContentParent .moreCategories .listBox button {
  margin: 0px !important;
}

.directoryContentParent .moreCategories .listBox img {
  width: 100%;
}

.directoryContentParent .noDataImage {
  margin: auto;
  background-color: white;
  width: 100%;
}

.directoryContentParent {
  min-height: 100vh;
}

.directoryContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.directoryContent button {
  margin: 25px auto !important;
  width: max-content;
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #304d50;
  cursor: pointer;
  color: white;
}

.directoryContent .eventInputField {
  height: 35px;
  border-radius: 4px;
  border: 1px solid black;
  padding: 6px;
  width: 65%;
}

.directory {
  height: 100%;
  width: 90%;
  margin: 0 auto 25px;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  margin: 25px auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
/* 
@media screen and (min-width: 1400px) {
  .directory {
    grid-template-columns: repeat(4, 1fr);
  }
} */

@media screen and (max-width: 991px) {
  .directory {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .directory {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }
}

.directory .card {
  width: 300px;
  position: relative;
  height: 300px;
  margin: 0 auto;
  background-color: rgb(255, 255, 255);
  color: rgba(47, 43, 61, 0.78);
  border-radius: 6px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  background-image: none;
  overflow: hidden;
}

.closeDialog {
  position: absolute;
  right: 20px;
  top: 20px;
  padding: 12px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(47, 43, 61, 0.1) 0px 4px 18px 0px;
  cursor: pointer;
}

.directory .card .bgImage {
  display: block;
  width: 100%;
  height: 200px !important;
}

.directory .card .content {
  text-transform: uppercase;
  padding: 0.75rem 1.3125rem 1rem !important;
}

.directory .card .content h1 {
  margin: 0px 0px 0.5rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1.3334;
  font-size: 1.125rem;
  color: rgba(47, 43, 61, 0.78);
}

.directory .card .content p {
  margin: 0px;
  line-height: 1.467;
  font-size: 0.9375rem;
  font-weight: 400;
  text-transform: none !important;
  color: rgba(47, 43, 61, 0.68);
}

.dialogContentBack {
  background-image: url("../../assets/images/businessPopBack.png");
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 !important;
  background-size: cover;
}

.directory .card button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  margin: 0 !important;
  justify-content: center;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.2;
  font-size: 0.9375rem;
  letter-spacing: 0.43px;
  font-weight: 500;
  border-radius: 0px 0px 6px 6px;
  color: rgb(255, 255, 255);
  background-color: #304d50;
  min-width: 50px;
  text-transform: none;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(47, 43, 61, 0.14) 0px 2px 6px 0px;
  padding: 0.625rem 1.25rem;
  width: 100%;
}

#business_number {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2;
  cursor: pointer;
}
