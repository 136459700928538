.heroSection {
  background-color: #304d50;
  max-width: 1100px;
  margin: 0 auto;
  width: 90%;
  /* height: 400px; */
  overflow: hidden;
  border-radius: 12px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contentForSmallDevice {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .heroSection {
    display: flex;
    max-height: 500px;
    overflow: visible;
    height: auto;
  }

  .heroSection .heroContent {
    display: none;
  }

  .heroSection .heroImage {
    position: relative;
    width: 100%;
  }

  .heroSection .heroImage img {
    border-radius: 12px;
    -webkit-transition: all 3s ease-in-out;
    -moz-transition: all 3s ease-in-out;
    transition: all 3s ease-in-out;
  }

  .heroSection .heroImage .searchBoxInsideImage {
    position: absolute;
    display: flex !important;
    left: 47%;
    width: 90%;
    transform: translateX(-50%);
    bottom: -125px;
    z-index: 1000 !important;
  }

  .contentForSmallDevice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
    max-width: 90%;
    gap: 15px;
    margin: 175px auto 0px;
  }

  .contentForSmallDevice h2,
  .contentForSmallDevice h4 {
    text-align: center;
    margin: 0px;
  }
}

.searchBoxInsideImage {
  display: none !important;
}

.heroSection .heroImage img {
  width: 100%;
  height: 100%;
}

.heroContent {
  color: white;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 30px 40px;
}

.SearchBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  justify-content: center;
}

.filterTab {
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0px !important;
  padding: 0px;
  list-style: none;
}

.filterTab li {
  cursor: pointer;
  padding: 12px;
}

.searchWrapper {
  margin: 0 !important;
  padding: 12px;
  border-radius: 0 12px 12px 12px;
  color: #222222;
  width: 100%;
  background-color: white;
}

.searchInputBox {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 15px;
}

.searchInputBox input {
  padding: 14px;
  width: 90%;
  border-radius: 8px;
  border: 1px solid #222222;
}

.searchInputBox input:focus {
  outline: none;
}

.searchInputBox button {
  border-radius: 8px;
  background-color: #304d50;
  color: white;
  border: 1px solid #222222;
  padding: 14px;
  cursor: pointer;
}
