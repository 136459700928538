.whyUs {
  background-image: url("../../assets/images/background-lighs.webp");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  margin-top: 75px;
  gap: 50px;
  color: white;
  padding: 30px;
  align-items: center;
  justify-content: center;
  height: max-content;
  background-position: bottom;
  margin-bottom: 0px;
  background-color: #3b865d;
}

.whyUs h1 {
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px;
}

.whyUs .imageContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 90%;
  margin: auto;
  column-gap: 20px;
  row-gap: 20px;
  align-items: flex-start;
}

.whyUs .imageContainer li {
  font-weight: bold;
  letter-spacing: 1.3;
  line-height: 30px;
}

.whyUs .imageContainer .imageDiv {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  min-width: 250px;
  margin: 0 auto;
}

.whyUs .imageContainer .imageDiv img {
  border-radius: 100%;
  height: 150px;
  margin: 0 auto;
  width: 150px;
}

@media only screen and (max-width: 1200px) {
  .whyUs .imageContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 640px) {
  .whyUs .imageContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}
