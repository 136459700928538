.errorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
  height: 100vh;
}

.errorWrapper button {
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #304d50;
  cursor: pointer;
  color: white;
}

.errorWrapper img {
  width: 50%;
}

@media only screen and (max-width: 1000px) {
  .errorWrapper {
    width: 90%;
    flex-direction: column-reverse;
  }

  .errorWrapper img {
    width: 90%;
  }
}
