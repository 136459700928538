.sb-button {
  display: none;
}

.adminWrapper {
  height: 100vh;
  display: flex;
  width: 100%;
}

.adminWrapper .sidebar {
  position: fixed;
}

.adminWrapper main {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
}

.sb-button-close {
  margin-left: auto;
  display: none;
}

@media only screen and (max-width: 576px) {
  .sb-button-close {
    display: block;
  }
  .sb-button {
    margin: 20px;
    display: block;
  }
}
