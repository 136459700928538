.links {
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
  padding: 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.links .header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}

.links .headerTitle input {
  border: 1px solid black;
  border-radius: 4px;
  padding: 6px;
  height: 40px;
  width: 275px;
  margin: 20px 0;
}

.links .button {
  padding: 12px;
  border: 1px solid transparent;
  border-radius: 12px;
  background-color: #304d50;
  cursor: pointer;
  color: white;
}
