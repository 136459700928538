.listingWrapper {
  min-height: 100vh;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  background-color: #f1e5cf;
}

.listingWrapper .header {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.listingWrapper .header h1 {
  font-weight: bolder !important;
  text-align: center;
  padding: 20px;
  margin: 0 !important;
  font-size: 40px;
}

.listing {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.listing .listBox {
  box-shadow: 0 2px 12px 0 rgba(20, 20, 43, 0.08);
  padding: 12px;
  border-radius: 12px;
  background-color: white;
}

.listing .listBox h3 {
  margin: 5px 0px;
}

.listing .listBox img {
  width: 300px;
  height: 250px;
  border-radius: 12px;
}

.listing .listBox button {
  color: rgb(255, 255, 255);
  background-color: #304d50;
  width: 90%;
  padding: 12px;
  border: 1px solid transparent;
  cursor: pointer;
  border-radius: 20px;
  margin-bottom: 20px;
}

/* @media screen and (min-width: 1400px) {
  .listing {
    grid-template-columns: repeat(4, 1fr);
  }
} */

@media screen and (max-width: 991px) {
  .listing {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 767px) {
  .listing {
    grid-column-gap: 32px;
    grid-row-gap: 32px;
    grid-template-columns: 1fr;
  }
}
